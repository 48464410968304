<template>
  <div>
    <b-row class="match-height">
      <b-col
        v-for="block in blocks"
        :key="block.block_id"
        cols="4"
      >
        <b-card
          :title="`${block.title} - ${formatDay(block.date)}`"
        >
          <b-button
            variant="outline-primary"
            @click="openModal(block.block_id)"
          >
            Compartilhar
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <!-- modal -->
    <b-modal
      ref="share-cards"
      hide-footer
      title="Using Component Methods"
      size="xl"
      @hide="clearDatas"
    >
      <div class="d-block text-center">
        <h3>Gerar imagens</h3>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div style="display: table; margin: 0 auto;">
            <button
              v-if="showDownload == false"
              class="btn btn-secondary"
              style="margin: 5px !important;"
              @click="print($refs.printMe)"
            >
              <b-overlay
                :show="generating"
                rounded="sm"
              >
                Gerar imagem feed
              </b-overlay>
            </button>

            <a
              v-if="showDownload == true"
              id="imge"
              class="btn btn-primary"
              :href="output"
              download="Cnn02021.png"
              style="margin: 5px !important;"
            >Baixar imagem
              <i
                class="fa fa-download fa-1x"
                style="color: white"
              />
            </a>
          </div>
          <div style="display: table; margin: 0 auto;">
            <img
              src="@/assets/images/novo-card-compartilhavel.jpeg"
              width="200px"
              alt="feed"
            >
          </div>
        </div>

        <div class="col-md-3">
          <div style="display: table; margin: 0 auto;top:100px;">
            <button
              v-if="showDownloadStory == false"
              class="btn btn-secondary"
              style="margin: 5px !important;"
              @click="print($refs.printMeStory)"
            >
              <b-overlay
                :show="generatingStory"
                rounded="sm"
              >
                Gerar imagem story
              </b-overlay>
            </button>

            <a
              v-if="showDownloadStory == true"
              id="imge"
              class="btn btn-primary"
              :href="outputStory"
              download="Cnn02021.png"
              style="margin: 5px !important;"
            >Baixar imagem
              <i
                class="fa fa-download fa-1x"
                style="color: white"
              />
            </a>
            <div style="display: table; margin: 0 auto;">
              <img
                src="@/assets/images/share_story.png"
                width="200px"
                alt="story"
              >
            </div>
          </div>
        </div>

        <div
          class="col-md-4"
          style="margin: 20px auto;max-width: 318.33px !important;"
        >
          <form @submit.prevent>
            <h5>Você pode alterar seu nome</h5>
            <input
              v-model="event_data.name"
              type="text"
              class="form-control speaker-data"
            >
          </form>
        </div>
      </div>
    </b-modal>
    <!-- FEED -->
    <div
      v-if="showCards"
      id="image"
      ref="printMe"
      style="position:absolute;top:10000px;left:10000px;"
    >
      <p
        class="title"
        style="display: table;font-family: Gotham;font-size:35px;
            position: relative;margin: 80px auto 0 auto;top:323px;color:#012d4f;"
      >
        {{
          formatDay(event_data.date, event_data.time).toUpperCase()
        }}
      </p>

      <img
        style="display: table;
            position: relative;margin: 7px auto 0 auto;top:380px;object-fit: cover;"
        :src="photo"
        alt="profile"
        width="500px"
        height="465px"
      >

      <p
        class="title"
        style="display: table;font-family: Gotham;font-size:42px;
            position: relative;margin: 80px auto 0 auto;top:323px;"
      >
        {{ event_data.name.toUpperCase() }}
      </p>

      <p
        class="title"
        style="display: table;font-family: Gotham;font-size:42px;
            position: relative;margin: 80px auto 0 auto;top:270px;font-weight: 200"
      >
        TEMA: {{ event_data.subject }}
      </p>
    </div>

    <!-- STORY -->
    <div
      v-if="showCards"
      id="imageStory"
      ref="printMeStory"
      style="position:absolute;top:10000px;left:10000px;"
    >
      <p
        class="title"
        style="display: table;font-family: Gotham;font-size:35px;
            position: relative;margin: 80px auto 0 auto;top:634px;color:#012d4f;"
      >
        {{
          formatDay(event_data.date, event_data.time).toUpperCase()
        }}
      </p>

      <img
        style="display: table;
            position: relative;margin: 7px auto 0 auto;top:695px;object-fit: cover;"
        :src="photo"
        alt="profile"
        width="500px"
        height="490px"
      >

      <p
        class="title"
        style="display: table;font-family: Gotham;font-size:42px;
            position: relative;margin: 80px auto 0 auto;top:640px;"
      >
        {{ event_data.name.toUpperCase() }}
      </p>

      <p
        class="title"
        style="display: table;font-family: Gotham;font-size:42px;
            position: relative;margin: 80px auto 0 auto;top:590px;font-weight: 200"
      >
        TEMA: {{ event_data.subject }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import $ from 'jquery'
import speakerService from './speakerBlocksServices'

export default {
  data() {
    return {
      baseStorage: process.env.VUE_APP_API_BASE,
      output: null,
      outputStory: null,
      showDownload: false,
      showDownloadStory: false,
      generating: false,
      generatingStory: false,
      imgReady: false,
      output_img: null,
      loading: false,
      speaker: {},
      blocks: [],
      event_data: {
        name: '',
        subject: '',
        date: '',
        time: '',
      },
      photo: '',
      photoStatus: null,
      showCards: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    eventBlock() {
      return this.$store.state.congress.eventBlock
    },
  },
  watch: {
    async speaker() {
      console.log(this.speaker)
      this.event_data.name = this.speaker.name_badge
      await this.UrlExists(this.speaker.photo)
    },
    user() {
      if (this.user.role === 'COMMON') {
        this.$router.push({ path: '/congressos' })
      }
    },
    eventBlock() {
      this.event_data.subject = this.eventBlock.title
      this.event_data.time = this.eventBlock.start_time
      this.event_data.date = this.eventBlock.event.date
      console.log(this.eventBlock)
    },
  },
  async mounted() {
    await speakerService().getSpeakerBlocks({ id: this.$route.params.id }).then(res => {
      this.blocks = res.blocks
      this.speaker = res.speaker
    })
    // this.ActionGetSpeakerBlocksNow()
    $(this.$refs.shareCards).on('hidden.bs.modal', this.doSomethingOnHidden)
  },
  methods: {
    ...mapMutations('congress', [
      'SET_SPEAKER_BLOCKS_NOW']),
    ...mapActions('congress', [
      'ActionLoadSpeaker',
      'ActionLoadEventsBlock',
      'ActionGetSpeakerBlocksNow',
    ]),
    async openModal(id) {
      this.showCards = true
      await this.ActionLoadEventsBlock({
        id,
      })
      this.$refs['share-cards'].show()
    },
    formatDay(date, time = 0) {
      let d = DateTime.fromISO(date, { setZone: true }).toJSDate()
      d = d.getDay()

      if (time === 0) {
        const dto = date.split('-')
        return `${dto[2]}/${dto[1]}/${dto[0]}`
      }

      const dayWeek = new Array(7)
      dayWeek[0] = 'Domingo'
      dayWeek[1] = 'Segunda-feira'
      dayWeek[2] = 'Terça-feira'
      dayWeek[3] = 'Quarta-feira'
      dayWeek[4] = 'Quinta-feira'
      dayWeek[5] = 'Sexta-feira'
      dayWeek[6] = 'Sábado'

      let dateFormated = date.split('-')
      dateFormated = `(${dateFormated[2]}/${dateFormated[1]})`

      let timeFormated = time.split(':')
      const tm = timeFormated[0]
      // eslint-disable-next-line radix
      if (parseInt(timeFormated[0]) <= 9 && parseInt(timeFormated[1] > 0)) {
        timeFormated = `${timeFormated[0].substring(0, 1)}H${timeFormated[1]}`
      // eslint-disable-next-line radix
      } else if (parseInt(timeFormated[0]) <= 9 && parseInt(timeFormated[1] === 0)) {
        timeFormated = `${timeFormated[0].substring(0, 1)}H`
      } else {
        timeFormated = `${timeFormated[0]}H${timeFormated[1]}`
      }

      return `${dayWeek[d]} ${dateFormated} ${timeFormated}`
    },
    UrlExists(url) {
      const path = `${this.baseStorage}/storage/image/${url}`
      const http = new XMLHttpRequest()
      http.open('HEAD', path, false)
      http.send()
      if (http.status === 200 && this.speaker.photo) {
        this.photo = `${this.baseStorage}/storage/image/${this.speaker.photo}`
      } else if (http.status === 200 && this.user.photo) {
        this.photo = `${this.baseStorage}/storage/image/${this.user.photo}`
      } else {
        this.photo = `${this.baseStorage}/public/assets/avatar-1577909.svg`
      }

      return http.status !== 404
    },
    async print(element) {
      const el = element
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL',
        allowTaint: false,
        useCORS: true,
      }
      if (element === this.$refs.printMe) {
        this.generating = true
        this.output = await this.$html2canvas(
          document.getElementById('image'),
          options
        )
        this.output_img = new Image()
        this.output_img.src = this.output
        this.output_img.width = 500
        // eslint-disable-next-line no-self-assign
        this.output_img.width = this.output_img.width
        this.output_img.height = 500
        // eslint-disable-next-line no-self-assign
        this.output_img.height = this.output_img.height
        this.output_img.onload = function () {}

        this.output = this.output_img.src
        this.showDownload = true
        this.generating = false
      } else if (element === this.$refs.printMeStory) {
        this.generatingStory = true
        this.outputStory = await this.$html2canvas(
          document.getElementById('imageStory'),
          options
        )
        this.output_img = new Image()
        this.output_img.src = this.outputStory
        this.output_img.width = 500
        // eslint-disable-next-line no-self-assign
        this.output_img.width = this.output_img.width
        this.output_img.height = 500
        // eslint-disable-next-line no-self-assign
        this.output_img.height = this.output_img.height
        console.log(this.output_img)
        this.output_img.onload = function () {}

        this.outputStory = this.output_img.src
        this.showDownloadStory = true
        this.generatingStory = false
      }
    },
    clearDatas() {
      this.photo = ''
      this.photoStatus = null
      this.showCards = false
      this.output = null
      this.outputStory = null
      this.showDownload = false
      this.showDownloadStory = false
      this.generating = false
      this.generatingStory = false
      this.imgReady = false
      this.output_img = null
    },
    notification() {
      Notification.requestPermission().then((permission) => {})
    },
  },
}
</script>

<style>
/* @font-face {
  font-family: 'Gotham';
  src: url('/assets/fonts/gotham/Gotham-Bold.eot');
   font-weight: 700;
   font-style: normal;
} */
.title {
  font-size: 23pt;
  font-family: 'Roboto Condensed' !important;
  font-weight: bold;
  color: white;
  margin-bottom: 10px !important;
  line-height: 25px;
}
.subtitle {
  font-size: 16px;
  font-family: 'Roboto' !important;
  color: white;
  margin-bottom: 10px !important;
  line-height: 25px;
}
.details {
  font-size: 14px;
  font-family: 'Roboto' !important;
  font-weight: bold;
  color: white;
  margin-bottom: 5px !important;
  padding-left: 3px;
}
.accordion > .card > .card-header {
  background-color: #0264a1;
}
body {
  background: #f6f6f6 !important;
}
.panel {
  height: 100% !important;
  background-color: #f6f6f6 !important;
  border: none;
}

.titleText {
  font-size: 50px;
  line-height: 53px;
  color: #737373;
  font-weight: 400;
  font-family: 'Roboto';
}

#title {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

#image {
  background-image: url('./../../../../assets/images/novo-card-compartilhavel.jpeg');background-size: 100%; width:1020px;height:1280px;
}

#imageStory {
  background-image: url('./../../../../assets/images/share_story.png');background-size: 100%; width:1080px;height:1920px;
}
</style>
